import React from "react";
import { Radio, RadioGroup, FormControlLabel } from "@mui/material";

const RadioButton = ({ value, inp_value, value_update }) => {
  return (
    <RadioGroup value={value} onChange={value_update}>
      <FormControlLabel className="radio_input"
        control={<Radio />}
        value={inp_value}
        checked={value === inp_value}
      />
    </RadioGroup>
  );
};

export default RadioButton;
