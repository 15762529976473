export const adminRoutes = {
  homePage: "dashboard",
  addProduct: "add-product",
  addCategory: "add-category",
  allProducts: "all-products",
  allCategories: "all-categories",
  allUsers: "all-users",
  allOrders: "all-orders",
  createBlog: "create-blog",
  allBlogs: "all-blogs",
  login: "login",
  orderDetails: "orderDetails",
};
