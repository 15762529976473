import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import AdminLogin from "./Admin/AdminLogin";
import AdminLayout from "./Routes/AdminLayout";
import { adminRoutes } from "./Routes/AdminRoutes";
import AdminRoutesData from "./Routes/AdminRoutesData";
import UserRoutesData from "./Routes/UserRoutesData";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <BrowserRouter>
      <Toaster
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <ScrollToTop />
      <Routes>
        <Route path={`admin/${adminRoutes.login}`} element={<AdminLogin />} />
        <Route path="admin/*" element={<AdminLayout />}>
          {Cookies.get("ishnooradminToken") ? (
            <Route path="*" element={<AdminRoutesData />} />
          ) : (
            <Route path="*" element={<AdminLogin />} />
          )}
        </Route>
        <Route path="/*" element={<UserRoutesData />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
