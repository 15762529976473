import { Box, Button, Grid } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Post } from "../Api/api";
import { URL_CONSTANTS } from "../Api/ApiUrl";
import Loader from "../components/Loader/Loader";
import { userRoutes } from "../Routes/UserRoutes";
import SDTextField from "../Supporting files/SDTextField/SDTextField";
import toast from "react-hot-toast";

const SignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phone: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (attrName, value) => {
    setFormValues((prev) => ({
      ...prev,
      [attrName]: value,
    }));
  };

  const validateForm = () => {
    const { firstName, lastName, email, password, phone } = formValues;

    if (!firstName.trim()) {
      toast.error("First name is required");
      return false;
    }
    if (!lastName.trim()) {
      toast.error("Last name is required");
      return false;
    }
    if (!email.trim()) {
      toast.error("Email is required");
      return false;
    }
    if (!password.trim()) {
      toast.error("Password is required");
      return false;
    }
    if (!phone.trim()) {
      toast.error("Phone number is required");
      return false;
    }
    return true;
  };

  const handleSignup = async () => {
    if (!validateForm()) {
      return;
    }
    setLoading(true);

    try {
      const response = await Post(
        `${URL_CONSTANTS.signUp}`,
        JSON.stringify(formValues)
      );
      toast.success("Your account is created successfully");
      navigate(userRoutes.login);
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loader />}
      <Box className="">
      {/* <Box className="mainWrapper"> */}
        <div className="login_screen">
          <div className="login">
            <Box>
              <Grid container padding={0} textAlign={"center"}>
                <Grid xs={12} className="mb-7">
                  <img
                    onClick={() => navigate(userRoutes.dashboard)}
                    src="/images/logo.png"
                    alt="logo"
                  />
                </Grid>
                <Grid xs={12} className="mb-7">
                  <h1 className="welcome">Create your Account</h1>
                </Grid>
                <Grid xs={12} className="mb-6">
                  <SDTextField
                    title="First name"
                    value={formValues.firstName}
                    attrName="firstName"
                    value_update={handleChange}
                    cls="w-100"
                  />
                </Grid>
                <Grid xs={12} className="mb-6">
                  <SDTextField
                    title="Last name"
                    value={formValues.lastName}
                    attrName="lastName"
                    value_update={handleChange}
                    cls="w-100"
                  />
                </Grid>
                <Grid xs={12} className="mb-6">
                  <SDTextField
                    title="Email"
                    type="email"
                    value={formValues.email}
                    attrName="email"
                    value_update={handleChange}
                    cls="w-100"
                  />
                </Grid>
                <Grid xs={12} className="mb-6">
                  <SDTextField
                    title="Password"
                    type="password"
                    value={formValues.password}
                    attrName="password"
                    value_update={handleChange}
                    cls="w-100"
                  />
                </Grid>
                <Grid xs={12} className="mb-6">
                  <SDTextField
                    title="Phone"
                    type="phone"
                    value={formValues.phone}
                    attrName="phone"
                    value_update={handleChange}
                    cls="w-100"
                  />
                </Grid>

                <Grid xs={12} className="ctaBtn mb-6">
                  <Button className="submitBtn w-100" onClick={handleSignup}>
                    Sign Up
                  </Button>
                </Grid>
                <Grid xs={12} className="mb-0">
                  <p className="forgot">
                    Already have an account?{" "}
                    <span onClick={() => navigate(userRoutes.login)}>
                      Login
                    </span>
                  </p>
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>
      </Box>
    </>
  );
};

export default SignUp;
