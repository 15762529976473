import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { URL_CONSTANTS } from "../Api/ApiUrl";
import { Get } from "../Api/api";
import { userRoutes } from "../Routes/UserRoutes";
import "../commonSCSS/common.scss";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Loader from "../components/Loader/Loader";

const BestSeller = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [activeCat, setActiveCat] = useState(null);
  const [bestSeller, setBestSeller] = useState([]);
  const [loader, setloader] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  const [hasMore, setHasMore] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const observer = useRef();

  //Get all category list
  const fetchCategories = async () => {
    try {
      const response = await Get(URL_CONSTANTS.getAllCategories);
      if (response.response?.length > 0) {
        setCategories(response.response);
      }
    } catch (error) {
      console.error("Error ", error.message);
    }
  };
  //Get new arrival
  const getBestSeller = async (id, pageNum = 1) => {
    try {
      const response = await Get(
        `${URL_CONSTANTS.getBestSeller}?categoryId=${
          id ?? ""
        }&page=${page}&pageSize=${pageSize}`
      );
      console.log("response", response);
      setloader(false);

      if (response.response.products.length > 0) {
        setBestSeller((prevArrivals) => [
          ...prevArrivals,
          ...response.response.products,
        ]);
        const totalLoaded = (pageNum - 1) * pageSize + response.response.products.length;
        setHasMore(totalLoaded < response.response.count);
        setLoadingMore(false);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching getNewArrival:", error.message);
    }
  };
  const lastProductRef = useCallback(
    (node) => {
      if (loadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setLoadingMore(true);
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loadingMore, hasMore]
  );

  useEffect(() => {
    if (page > 1) {
      getBestSeller(activeCat ?? "", page, pageSize); // Fetch the next page
    }
  }, [page]);
  useEffect(() => {
    fetchCategories();
    getBestSeller();
  }, []);

  return (
    <>
      {loader && <Loader />}
      <Box className="mainWrapper">
        <Header />
        <Box className="shopSection">
          <Box className="shop_box">
            <h2 className="theme_heading">
              Best <span>Seller</span>
            </h2>
            <Box marginBottom={"80px"}>
              <Grid container spacing={3} className="category_container">
                <Grid xs={12}>
                  <div className="cat_scroller">
                    {categories?.length > 0 &&
                      categories?.map((categoryData, i) => (
                        <div
                          className="category pb-0"
                          key={categoryData?._id}
                          onClick={() => {
                            setPage(1);
                            setBestSeller([]);
                            getBestSeller(categoryData._id);
                          }}
                        >
                          <img
                            onError={(e) => {
                              e.target.onError = null;
                              e.target.src = "images/dummy_category.jpg";
                            }}
                            className={`${
                              activeCat === categoryData._id ? "active" : ""
                            }`}
                            onClick={() => {
                              setActiveCat(categoryData._id);
                            }}
                            src={
                              categoryData.image || "images/dummy_category.jpg"
                            }
                            alt={categoryData.title}
                          />
                          <p>{categoryData.title}</p>
                        </div>
                      ))}
                  </div>
                </Grid>
              </Grid>
            </Box>
            <Box className="style arrival mb-8">
              <Grid container columnSpacing={3} rowSpacing={6}>
                {bestSeller?.map((item, index) => {
                  const classes = [
                    "img_slide",
                    "top_small",
                    "bottom_small",
                    "img_slide",
                  ];
                  let ind = index % 4;
                  let cls = classes[ind];
                  return (
                    <Grid
                      xs={3}
                      className="style_slider"
                      onClick={() => {
                        navigate(
                          `${userRoutes.productDetail}?productId=${item._id}`
                        );
                      }}
                    >
                      <Box className={`img_slide ${cls}`}>
                        <img
                          className="arrival_img"
                          src={item?.variants[0]?.images[0]}
                        />
                        <Grid
                          container
                          columnSpacing={4}
                          className="size_container"
                        >
                          {item?.variants.map((colors, sizeIndex) => (
                            <div
                              key={sizeIndex}
                              className="color_box"
                              style={{
                                backgroundColor: colors.color,
                              }}
                            ></div>
                            // <Grid xs={1} className="ctaBtn">
                            //     <Button
                            //         variant="contained"
                            //         className="whiteBtn xsbtn"
                            //         style={{
                            //             backgroundColor: colors.color,
                            //         }}
                            //     ></Button>
                            // </Grid>
                          ))}
                        </Grid>
                      </Box>
                    </Grid>
                  );
                })}
                {/* Ref for the last product to trigger more load */}
                <div ref={lastProductRef} style={{ height: "20px" }} />
                {/* 
                <Grid xs={12} className="ctaBtn text-center">
                  <Button
                    variant="contained"
                    className="submitBtn"
                    // onClick={() => navigate(userRoutes.dashboard)}
                  >
                    View All
                  </Button>
                </Grid> */}
              </Grid>
            </Box>
          </Box>
        </Box>
        <Footer />
      </Box>
    </>
  );
};
export default BestSeller;
