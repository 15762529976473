import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import "./CustomTable.scss";

const CustomTable = ({
  header_data,
  data,
  value_update,
  refetch_data,
  totalRecords,
  setTableLimit,
  setTableSkip,
  setPageNumberData,
  checkboxSelection,
  hideThreeDot,
}) => {
  const [columnSortingPopUp, setColumnSortingPopUp] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [columns, setColumns] = useState(header_data);

  useEffect(() => {
    setColumns(header_data);
  }, [header_data]);

  // Unified function for handling both page change and page size change
  const handlePaginationModelChange = (model) => {
    const { page, pageSize } = model;

    if (paginationModel.page !== page) {
      console.log("Page changed", page, pageSize);
      setTableSkip(page * pageSize);
      refetch_data(page, pageSize);
      if (setPageNumberData) setPageNumberData(page);
    }

    if (paginationModel.pageSize !== pageSize) {
      console.log("Page size changed", pageSize, page);
      setTableLimit(pageSize);
      refetch_data(page, pageSize);
    }

    setPaginationModel(model);
  };

  return (
    <Box className="tableHeight" sx={{ display: "flex", width: "100%" }}>
      <div className="filterBar">
        {!hideThreeDot && (
          <Button onClick={() => setColumnSortingPopUp(!columnSortingPopUp)}>
            <span className="bars" />
          </Button>
        )}
      </div>
      <DataGrid
        className="dataList"
        rows={data}
        rowCount={totalRecords}
        pageSize={paginationModel.pageSize}
        page={paginationModel.page}
        columns={columns || header_data}
        paginationMode="server"
        paginationModel={paginationModel} // Pass the pagination model
        onPaginationModelChange={handlePaginationModelChange} // Unified handler for page and size changes
        rowsPerPageOptions={[10,25, 50, 100]}
        checkboxSelection={checkboxSelection || true}
        onSelectionModelChange={(ids) => value_update("selected_data", ids)}
        disableColumnSelector
        disableSelectionOnClick
      />
    </Box>
  );
};

export default CustomTable;
