import React, { useEffect, useState } from "react";
import { Button, Box, Typography } from "@mui/material";
import { CloudUpload as CloudUploadIcon } from "@mui/icons-material";
import axios from "axios";
import { URL_CONSTANTS } from "../Api/ApiUrl";

const ImageUploader = ({ onUploadSuccess }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState("");

  const handleFileChange = (e) => {
    setSelectedFiles(e.target.files);

  };
  useEffect(() => {
    handleUpload();
  }, [selectedFiles]);

  const handleUpload = async () => {
    setUploading(true);
    setError("");
    try {
      const formData = new FormData();
      for (let i = 0; i < selectedFiles.length; i++) {
        formData.append("images", selectedFiles[i]);
      }

      const response = await axios.post(URL_CONSTANTS.imageUploader, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const urls = response.data.response.map((img) => img.url);
      onUploadSuccess(urls);
    } catch (error) {
      setError("Failed to upload images");
      console.error("Error uploading images", error);
    } finally {
      setUploading(false);
    }
  };

  return (
    <>
      <Box className="img_uploader">
        <input className="uploader_input" type="file" multiple onChange={handleFileChange} />
        <img className="uploader_icon" src="images/uploader.svg" alt="upload" />
        {error && <Typography color="error">{error}</Typography>}
      </Box>
    </>
  );
};

export default ImageUploader;
