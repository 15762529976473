import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Link, Modal, TextField } from "@mui/material";
import Rating from "@mui/material/Rating";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { Post } from "../../Api/api";
import { URL_CONSTANTS } from "../../Api/ApiUrl";
import "../../commonSCSS/modals.scss";
import TextArea from "../../Supporting files/TextArea/TextArea";
import ImageUploader from "../ImageUploader";
import toast from "react-hot-toast";

const ReviewModal = ({
  open,
  onClose,
  orderData,
  selectedProduct,
  isEdit,
  editReviewData,
  onSuccess,
}) => {
  const [rating, setRating] = useState(5);
  const [reviewText, setReviewText] = useState("");
  const [uploadedImageUrls, setUploadedImageUrls] = useState([]);
  const handleImageUploadSuccess = (uploadedUrls) => {
    const newImages = uploadedUrls.map((url) => ({
      imageUrl: url,
    }));
    if (uploadedUrls.length > 0) {
      setUploadedImageUrls([...uploadedImageUrls, ...newImages]);
    }
  };

  useEffect(() => {
    if (isEdit) {
      setRating(editReviewData?.rating);
      setReviewText(editReviewData?.comment);
      setUploadedImageUrls(editReviewData?.productImg);
    }
  }, []);
  const handleSubmit = () => {
    console.log({ rating, reviewText, uploadedImageUrls });
    submitReview();
  };
  const updateMaster = (attr, value) => {
    attr(value);
  };

  const submitReview = async () => {
    if (!reviewText) {
      return toast.error("Please write review");
    }
    try {
      let payload = {
        productId: selectedProduct.productId,
        rating,
        comment: reviewText,
        image: uploadedImageUrls,
        orderId: orderData?._id,
      };
      if (isEdit) {
        payload = {
          ...payload,
          reviewId: editReviewData._id,
          productImg: uploadedImageUrls,
        };
        delete payload.orderId;
        delete payload.image;
        await Post(URL_CONSTANTS.updateReview, payload);
      } else {
        await Post(URL_CONSTANTS.writeReview, payload);
      }
      onSuccess();
      onClose();
      setRating(5);
      setReviewText("");
      setUploadedImageUrls([]);
    } catch (error) {
      console.error("Submission error", error);
    }
  };

  const removeImg = (url) => {
    let data = uploadedImageUrls?.filter((sman) => sman !== url);
    setUploadedImageUrls(data);
  };

  return (
    <Modal open={open} className="modalWrapper">
      <Box className="modalInner lg-width">
        <Grid container spacing={3}>
          <Grid xs={9}>
            <h5 className="popup_heading">Write Review</h5>
          </Grid>
          <Grid xs={3} className="close_button">
            <Link onClick={onClose}>
              <CloseIcon />
            </Link>
          </Grid>
          <Grid xs={12} className="divider p-0 mx-3"></Grid>
          <Grid xs={12}>
            <Box className="review_box mb-3">
              <img
                className="mr-4 pro_img"
                src={selectedProduct?.productDetail?.variants.images[0]}
                alt="pro"
              />
              <Box className="review_title">
                <h5>{selectedProduct?.productDetail?.title}</h5>
                <Rating
                  name="product-rating"
                  value={rating}
                  precision={0.5}
                  onChange={(event, newValue) => setRating(newValue)}
                  size="medium"
                />
              </Box>
            </Box>
            <TextArea
              title={"Address"}
              value={reviewText}
              value_update={updateMaster}
              attrName={setReviewText}
              warn_status={false}
              class_name={"inputTextfield"}
              placeholder="Please Write Review Product Here..."
              rows={5}
            />

            <Box className="galerry">
              <ImageUploader onUploadSuccess={handleImageUploadSuccess} />
              <p className="title">Add Photos</p>
              <Box className="uploaded_images">
                {uploadedImageUrls?.map((url, index) => (
                  <Box className="im_box" key={index}>
                    <img
                      className="cross"
                      src={url.imageUrl}
                      alt={`Uploaded ${index}`}
                    />
                    <CloseIcon
                      className="cross-icon"
                      onClick={() => removeImg(url)}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          </Grid>
          <Grid xs={12} className="ctaBtn" textAlign={"center"}>
            <Button className="mdBtn" onClick={handleSubmit}>
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default ReviewModal;
