export const userRoutes = {
  dashboard: "/",
  newArrivals: "/new-arrivals",
  bestSeller: "/bestSeller",
  featuredProduts: "/featuredProduts",
  categories: "/categories",
  wishlist: "/wishlist",
  cart: "/cart",
  products: "/products",
  productDetail: "/product-detail",
  blogs: "/blogs",
  blogDetail: "/blog-detail",
  checkout: "/checkout",
  orders: "/orders",
  orderDetail: "/order-detail",
  login: "/login",
  forgotPassword: "/forgot-password",
  signUp: "/sign-up",
  contactUs: "/contact-us",
  editProfile: "/edit-profile",
  payment: "/payment",
  aboutUs: "/about-us",
  contactUs: "/contact-us",
  privacy: "/privacy-policy",
  terms: "/terms-of-service",
  returnExchange: "/return-exchange-policy",
  returnRequest: "/return-exchange-request",
  shipping: "/shipping",
  faq: "/faq",
};