import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const initialState = {
  adminInfo: {}, // Define the initial state with a default structure
};

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setAdmindata: (state, action) => {
      state.adminInfo = action.payload;
    },
    logoutAdmin: (state) => {
      state.adminInfo = {};
      Cookies.remove("ishnooradminToken");
    },
  },
});

export const { setAdmindata, logoutAdmin } = adminSlice.actions;

export default adminSlice.reducer;
