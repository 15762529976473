import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useEffect, useState } from "react";
import { formatCurrency } from "../Supporting files/HelpingFunction";
import Footer from "../components/Footer";
import { URL_CONSTANTS } from "../Api/ApiUrl";
import { Get, Post, Put } from "../Api/api";
import toast from "react-hot-toast";
import { userRoutes } from "../Routes/UserRoutes";
import Loader from "./../components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { updateCart, updateWishlist } from "../Redux/Slice/UserSlice";

const CartData = () => {
  const wishlistData = useSelector((state) => state.user.wishlist);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cartItems, setcartItems] = useState([]);
  const [isLoader, setIsLoader] = useState(true);

  const handleChange = (value, item) => {
    updateQuantity(item.productId, item.variantId, item.sizeId, value);
  };

  useEffect(() => {
    fetchCart();
  }, []);
  const removeFromCart = async (productId, variantId, sizeId) => {
    let dto = {
      productId,
      variantId,
      sizeId,
      quantity: 1,
    };
    try {
      const response = await Put(
        `${URL_CONSTANTS.removeCartItems}`,
        JSON.stringify(dto)
      );
      toast.success("Cart updated successfully");
      console.log("response", response.response.products);
      fetchCart();
    } catch (error) {
      console.error("Error ", error);
    }
  };

  const moveToWishList = async (productId, variantId, sizeId) => {
    let dto = {
      productId,
      variantId,
      sizeId,
    };
    try {
      const response = await Put(
        `${URL_CONSTANTS.moveToWishList}`,
        JSON.stringify(dto)
      );
      fetchCart();
      dispatch(updateWishlist(response.response.products));
      toast.success("Added to Wishlist successfully");
    } catch (error) {
      console.error("Error ", error);
    }
  };

  const fetchCart = async () => {
    try {
      const response = await Get(`${URL_CONSTANTS.getCartItems}`);
      setcartItems(response?.response?.products);
      setIsLoader(false);
      dispatch(updateCart(response?.response?.products));
    } catch (error) {
      setcartItems([]);
      dispatch(updateCart([]));
      setIsLoader(false);
      console.error("Error ", error.message);
    }
  };

  const updateQuantity = async (productId, variantId, sizeId, quantity) => {
    let dto = {
      productId,
      variantId,
      sizeId,
      quantity,
    };
    try {
      const response = await Post(
        `${URL_CONSTANTS.AddCartItems}`,
        JSON.stringify(dto)
      );
      fetchCart();
      toast.success("Quantity updated successfully");
    } catch (error) {
      console.error("Error ", error);
    }
  };

  return (
    <>
      {isLoader && <Loader />}
      <Box className="mainWrapper">
        <Header />
        <Box className="cartSection">
          <h2 className="theme_heading">
            My <span>Cart</span>
          </h2>
          <Grid container rowSpacing={3}>
            {Array.isArray(cartItems) &&
              cartItems?.length > 0 &&
              cartItems.map((item) => {
                const maxQuantity =
                  item?.productDetail?.variants?.sizes?.quantity || 1;
                const options = Array.from(
                  { length: Math.min(maxQuantity, 6) },
                  (_, i) => i + 1
                );
                let is_WishlistExist = wishlistData.some(
                  (productData) =>
                    productData.sizeId ===
                    item?.productDetail?.variants?.sizes?._id
                );

                return (
                  <Grid
                    lg={12}
                    key={item.id}
                    style={{ position: "relative", cursor: "pointer" }}
                  >
                    <Box className="detail_info">
                      <img
                        className="cart-img"
                        src={item?.productDetail?.variants.images[0]}
                        alt="insta"
                        onClick={() => {
                          navigate(
                            `${userRoutes.productDetail}?productId=${item.productId}`
                          );
                        }}
                      />
                      <Box className="mainDiv">
                        <div className="details">
                          <h2 className="bold mb-3">
                            {item?.productDetail?.title}
                          </h2>
                          <h2 className="p_text mb-3">
                            Color:
                            <span
                              style={{
                                display: "inline-block",
                                width: "16px",
                                height: "16px",
                                borderRadius: "50%",
                                backgroundColor:
                                  item?.productDetail?.variants?.color,
                                marginLeft: "8px",
                                verticalAlign: "middle",
                              }}
                            ></span>
                          </h2>

                          <h2 className="p_text mb-3">
                            Size: {item?.productDetail?.variants?.sizes?.size}
                          </h2>
                          <h2 className="p_text mb-3">
                            {formatCurrency(
                              item?.productDetail?.variants?.sizes?.price
                            )}
                          </h2>
                          <h2 className="bold mb-3">Quantity</h2>
                          <Box className="dropDown">
                            {Number(
                              item.productDetail.variants.sizes.quantity
                            ) > 0 && (
                              <FormControl fullWidth>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id={`demo-simple-select-${item.id}`}
                                  value={item.quantity}
                                  onChange={(e) =>
                                    handleChange(e.target.value, item)
                                  }
                                >
                                  {options.map((option) => (
                                    <MenuItem key={option} value={option}>
                                      {option}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            )}
                          </Box>
                          {Number(item.productDetail.variants.sizes.quantity) <
                            1 && (
                            <span style={{ color: "#8E282B" }}>
                              Out of stock
                            </span>
                          )}
                        </div>
                        <div className="action_btns">
                          <h5
                            onClick={() => {
                              removeFromCart(
                                item.productId,
                                item?.variantId,
                                item?.sizeId
                              );
                            }}
                          >
                            Remove
                          </h5>
                          <h5
                            onClick={() => {
                              if (!is_WishlistExist) {
                                moveToWishList(
                                  item.productId,
                                  item?.variantId,
                                  item?.sizeId
                                );
                              }
                            }}
                          >
                            {is_WishlistExist
                              ? "Moved To Wishlist"
                              : "Move To Wishlist"}
                          </h5>
                        </div>
                      </Box>
                    </Box>
                  </Grid>
                );
              })}

            <Grid xs={12} className="ctaBtn text-center mt-20 p-0">
              <Button
                variant="contained"
                className="submitBtn"
                onClick={() => {
                  if (
                    cartItems.some(
                      (item) => item.productDetail.variants.sizes.quantity < 1)
                  ) {
                    toast.error("Some of the products is out of stock");
                  } else {
                    navigate(userRoutes.checkout);
                  }
                }}
              >
                Place Order
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Footer />
      </Box>
    </>
  );
};

export default CartData;
