import React, { useEffect, useState } from "react";
import {
  Container,
  Card,
  CardContent,
  Typography,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@mui/material";
import { styled } from "@mui/system";
import { ADMINURL_CONSTANTS } from "./AdminApiUrl/AdminApiUrl";
import { Get } from "../Api/api";
import Loader from "../components/Loader/Loader";
import { useLocation } from "react-router-dom";

const ShadowCard = styled(Card)(({ theme }) => ({
  boxShadow: theme.shadows ? theme.shadows[3] : "0px 3px 6px rgba(0,0,0,0.1)",
  marginBottom: theme.spacing(2),
  background: "linear-gradient(135deg, #f3f3f3 0%, #e9e9e9 100%)",
  display: "flex",
  flexDirection: "column",
  height: "100%",
}));
const OrderDetails = () => {
  const location = useLocation();
  const [orderDetailsData, setorderDetailsData] = useState({});
  const [loading, setLoading] = useState(true);
  const {
    products,
    deliveryAddress,
    paymentMode,
    deliveryStatus,
    totalAmount,
  } = orderDetailsData;
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id");
    orderDetail(id);
  }, []);

  //Get new blog
  const orderDetail = async (id) => {
    try {
      const response = await Get(`${ADMINURL_CONSTANTS.orderDetails}${id}`);
      setorderDetailsData(response.response);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching Blogs:", error.message);
    }
  };

  return (
    <Container>
      {loading && <Loader />}
      <Typography variant="h4" component="h2" gutterBottom>
        Order Details
      </Typography>
      <Grid container spacing={3} mb={4}>
        <Grid item xs={12} md={6}>
          <ShadowCard>
            <CardContent>
              <Typography variant="h6" component="h3" gutterBottom>
                Delivery Address
              </Typography>
              <Typography>{deliveryAddress?.name}</Typography>
              <Typography>{deliveryAddress?.phone}</Typography>
              <Typography>{deliveryAddress?.address}</Typography>
              <Typography>
                {deliveryAddress?.city}, {deliveryAddress?.district} -{" "}
                {deliveryAddress?.pincode}
              </Typography>
              <Typography>Tag: {deliveryAddress?.tag}</Typography>
            </CardContent>
          </ShadowCard>
        </Grid>

        <Grid item xs={12} md={6}>
          <ShadowCard>
            <CardContent>
              <Typography variant="h6" component="h3" gutterBottom>
                Payment Mode
              </Typography>
              <Typography>{paymentMode}</Typography>

              <Typography
                variant="h6"
                component="h3"
                gutterBottom
                style={{ marginTop: "16px" }}
              >
                Delivery Status
              </Typography>
              <Typography>{deliveryStatus}</Typography>

              <Typography
                variant="h6"
                component="h3"
                gutterBottom
                style={{ marginTop: "16px" }}
              >
                Total Amount
              </Typography>
              <Typography>₹{totalAmount}</Typography>
            </CardContent>
          </ShadowCard>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Product Image</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Size</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>MRP</TableCell>
              <TableCell>Discount</TableCell>
              <TableCell>Quantity</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products?.map((product) => (
              <TableRow key={product?._id}>
                <TableCell>
                  <img
                    src={product?.productDetail?.variants?.images[0]}
                    alt={product?.productDetail?.title}
                    style={{ width: "100px", height: "auto" }}
                  />
                </TableCell>
                <TableCell>{product?.productDetail?.title}</TableCell>
                <TableCell>
                  {product?.productDetail?.variants?.sizes?.size}
                </TableCell>
                <TableCell>
                  ₹{product?.productDetail?.variants?.sizes?.price}
                </TableCell>
                <TableCell>
                  ₹{product?.productDetail?.variants?.sizes?.mrp}
                </TableCell>
                <TableCell>
                  ₹{product?.productDetail?.variants?.sizes?.discount}
                </TableCell>
                <TableCell>{product?.quantity}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default OrderDetails;
