import React from 'react';
import './Loader.scss';

const Loader = () => {
    return (
        <div className="loader-container">
            <div className="loader">
                <span className="hour"></span>
                <span className="min"></span>
                <span className="circel"></span>
            </div>
        </div >
    );
}

export default Loader;