import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import SDTextField from "../Supporting files/SDTextField/SDTextField";
import TextArea from "../Supporting files/TextArea/TextArea";

const EditProfile = () => {
    const navigate = useNavigate();

    return (
        <>
            <Box className="mainWrapper">
                <Header />
                <Box className="editProfileSection">
                    <Box className="">
                        <Grid container padding={0} columnSpacing={2}>
                            <Grid xs={12} className="heading mb-8">
                                Edit Profile
                            </Grid>
                            <Grid lg={4}>
                                <SDTextField
                                    title="First Name"
                                    value={"first name"}
                                    attrName={"name"}
                                    value_update={() => { }}
                                    cls="w-100"
                                />
                            </Grid>
                            <Grid lg={4}>
                                <SDTextField
                                    title="Last Name"
                                    value={""}
                                    attrName={"name"}
                                    value_update={() => { }}
                                    cls="w-100"
                                />
                            </Grid>
                            <Grid lg={4}>
                                <SDTextField
                                    title="Email"
                                    value={""}
                                    attrName={"name"}
                                    value_update={() => { }}
                                    cls="w-100"
                                />
                            </Grid>
                            <Grid lg={12} className="labell mt-4 mb-2">
                                Billing address
                            </Grid>
                            <Grid xs={12}>
                                <TextArea
                                    title={"Address"}
                                    value_update={()=>{}}
                                    attrName={[""]}
                                    warn_status={false}
                                    class_name={"inputTextfield"}
                                    placeholder={'Address'}
                                />
                            </Grid>
                            <Grid xs={12} className="labell my-4">
                                Alternate mobile details
                            </Grid>
                            <Grid lg={4}>
                                <SDTextField
                                    title="Contact"
                                    value={""}
                                    attrName={"name"}
                                    value_update={() => { }}
                                    cls="w-100"
                                />
                            </Grid>
                            <Grid xs={12} className="ctaBtn mt-8 mb-4" textAlign={'center'}>
                                <Button className="submitBtn">
                                    Submit Details
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Footer />
            </Box>
        </>
    )
}

export default EditProfile;