import {
  Cancel as CancelIcon,
  CheckCircle as CheckCircleIcon,
} from "@mui/icons-material";
import { Box, Button, Container, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import "../Admin/admin.scss";
import { Delete } from "../Api/api";
import CustomTable from "../Supporting files/CustomTable/CustomTable";
import { ADMINURL_CONSTANTS } from "./AdminApiUrl/AdminApiUrl";
const AllCategories = () => {
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [tableSkip, setTableSkip] = useState();
  const [tableLimit, setTableLimit] = useState();
  const [categories, setCategories] = useState([
    {
      field: "id",
      headerName: "ID",
      minWidth: 250,
      check_status: true,
    },
    {
      field: "title",
      headerName: "Name",
      minWidth: 300,
      check_status: true,
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 300,
      check_status: true,
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 200,
      check_status: true,
      renderCell: (params) => {
        const { row } = params;
        console.log("row.isActive:", row.isActive); // Debugging line

        return row.isActive ? (
          <Button
            onClick={() => {
              activeOrInactiveCategory(row.id, "Category is InActived");
            }}
            variant="contained"
            style={{
              color: "#fff",
              backgroundColor: "#fb5044e3",
              padding: "6px 12px",
              display: "flex",
              alignItems: "center",
            }}
            startIcon={<CancelIcon />}
          >
            Make it Inactive
          </Button>
        ) : (
          <Button
            onClick={() => {
              activeOrInactiveCategory(row.id, "Category is Actived");
            }}
            variant="contained"
            style={{
              color: "#fff",
              backgroundColor: "#7ac57d",
              padding: "6px 12px",
              display: "flex",
              alignItems: "center",
            }}
            startIcon={<CheckCircleIcon />}
          >
           Make it Active
          </Button>
        );
      },
    },
  ]);
  const activeOrInactiveCategory = async (id, message) => {
    try {
      const response = await Delete(
        `${ADMINURL_CONSTANTS.activeOrInactiveCategory}${id}`
      );
      getAllCategories();
      toast.success(message);
    } catch (error) {
      console.error("Error ", error.message);
    }
  };
  useEffect(() => {
    // Fetch categories from API
    getAllCategories();
  }, []);

  const getAllCategories = (page = 0, pageSize = 25) => {
    axios
      .get(
        `${ADMINURL_CONSTANTS.GetAllCategory}?page=${
          page + 1
        }&pageSize=${pageSize}`
      )
      .then((response) => {
        const categories = response.data.response.map((category) => ({
          ...category,
          id: category._id,
        }));
        setCategoryOptions(categories);
      })
      .catch((error) => {
        console.error("Error fetching categories", error);
      });
  };
  const updateMasterState = () => {};

  return (
    <Container className="adminWrapper">
      <Box sx={{ mt: 3 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          All Categories
        </Typography>
        <CustomTable
          header_data={categories}
          data={categoryOptions}
          value_update={updateMasterState}
          attrName={"UGTable"}
          BE_filter_code={20}
          totalRecords={totalCount}
          refetch_data={getAllCategories}
          setTableLimit={setTableLimit}
          setTableSkip={setTableSkip}
        />
      </Box>
    </Container>
  );
};

export default AllCategories;
