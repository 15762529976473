import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "../commonSCSS/common.scss";
import Footer from "../components/Footer";
import Header from "../components/Header";

const AboutUs = () => {
    return (
        <>
            <Box className="mainWrapper">
                <Header />
                <Box className="our_blogs about_page">
                    <Box>
                        <h1>About Us</h1>
                        <h2>
                            Home/<span> About Us</span>
                        </h2>
                    </Box>
                    <Box className="aboutSection">
                        <Box className="headingg mb-3">
                            <h2>
                                Welcome To Ishnoor Store! Let Us Take You On A Journey Through The Ages.
                            </h2>
                        </Box>
                        <Box>
                            <Grid container padding={0}>
                                <Grid xs={12}>
                                    <h3 className="para mb-5">Ishnoor is a homegrown label honouring an age-old craft which believes chikankari is a piece of art. The brand is fully homegrown and its entire manufacturing process, whether it's procuring the material or the hand embroidery, is done locally by women artisans. We offer chikankari products with a modern twist and we work towards promoting the same.</h3>
                                </Grid>
                                <Grid xs={12}>
                                    <h3 className="para mb-5">Ishnoor is taking chikankari to places, at least away from our long kurta sets that typically involve white chikan work on plain, pastel & bright fabric like Modal, Muslin, Chanderi, Mul Mul and Rayon to printed Poly Silk fabric and bolder colours. Not just this, like promised, the brand also makes chikankari modern crafting beautiful pleated A-line dresses, Short Kurtas and Kurtis, Maxi and Short Dresses, gowns, stylish Kaftans and even Co-ord Sets all highlighting chikankari, front and center. Overall, the vibe is fresh, clean, extremely wearable and present-day chic.</h3>
                                </Grid>
                                <Grid xs={12}>
                                    <h3 className="para mb-5">If you're missing desi, ishnoor also stocks and makes traditional kurta sets and more. Oh, and all Our clothing is strictly made to have a relaxed and comfortable fit. You can live in kari, all day, no worry!</h3>
                                </Grid>
                                <Grid xs={12}>
                                    <img className="abtImg w-100" src="images/abtus.svg" alt="about" />
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>

                <Footer />
            </Box>
        </>
    );
};

export default AboutUs;