import React from "react";

const AdminDashboard = () => {
  const dashboardStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    textAlign: "center",
  };

  return (
    <div style={dashboardStyle}>
      <h1>Hello Admin</h1>
    </div>
  );
};

export default AdminDashboard;
