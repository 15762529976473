import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box, Button, Rating } from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { URL_CONSTANTS } from "../Api/ApiUrl";
import { Delete, Get, Post, Put } from "../Api/api";
import { updateCart, updateWishlist } from "../Redux/Slice/UserSlice";
import {
  capitalizeFirstLetter,
  formatCurrency,
} from "../Supporting files/HelpingFunction";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Loader from "../components/Loader/Loader";
import Magnifier from "../components/Magnifier";
import Modal from "@mui/material/Modal";
const ProductDetail = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const cartData = useSelector((state) => state?.user?.cart);
  const wishlistData = useSelector((state) => state?.user?.wishlist);
  const searchParams = new URLSearchParams(location.search);
  const productId = searchParams.get("productId");
  const [selectedVarientsId, setselectedVarientsId] = useState("");
  const [selectedSizesId, setselectedSizesId] = useState("");
  const [selectedImage, setselectedImage] = useState("");
  const [productItem, setProductItem] = useState();
  const [AllReview, setAllReview] = useState();
  const [isExpanded, setIsExpanded] = useState(false);
  const [loader, setLoader] = useState(true);
  const [is_WishlistExist, setis_WishlistExist] = useState(false);
  const [is_CartExist, setis_CartExist] = useState(false);
  const [visibleReviews, setVisibleReviews] = useState(5); // Start with 5 reviews
  const [openReviewImage, setopenReviewImage] = useState(false);
  const [selectedImageReview, setSelectedImageReview] = useState(null);

  const handleShowMore = () => {
    setVisibleReviews((prev) => prev + 5);
  };
  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };
  useEffect(() => {
    productDetail(productId);
    productReview(productId);
  }, []);

  const addToCart = async () => {
    let dto = {
      productId,
      variantId: selectedVarientsId,
      sizeId: selectedSizesId,
      quantity: 1,
    };
    try {
      const response = await Post(
        `${URL_CONSTANTS.AddCartItems}`,
        JSON.stringify(dto)
      );
      dispatch(updateCart(response.response.products));
      toast.success("Cart updated successfully");
    } catch (error) {
      console.error("Error ", error);
    }
  };
  const removeFromCart = async () => {
    let dto = {
      productId,
      variantId: selectedVarientsId,
      sizeId: selectedSizesId,
      quantity: 1,
    };
    try {
      const response = await Put(
        `${URL_CONSTANTS.removeCartItems}`,
        JSON.stringify(dto)
      );
      toast.success("Cart updated successfully");
      dispatch(updateCart(response.response.products));
    } catch (error) {
      console.error("Error ", error);
    }
  };

  const deleteWishList = async (productId, variantId, sizeId) => {
    let payload = {
      productId,
      variantId,
      sizeId,
    };

    try {
      const response = await Delete(`${URL_CONSTANTS.deleteWishlist}`, payload);
      toast.success("Wishlist updated successfully");
      dispatch(updateWishlist(response.response.products));
    } catch (error) {
      console.error("Error ", error.message);
    }
  };
  const addToWishlist = async () => {
    let dto = {
      productId,
      variantId: selectedVarientsId,
      sizeId: selectedSizesId,
    };
    try {
      const response = await Post(
        `${URL_CONSTANTS.AddToWishlist}`,
        JSON.stringify(dto)
      );
      dispatch(updateWishlist(response.response.products));
      toast.success("Wishlist updated successfully");
    } catch (error) {
      console.error("Error ", error);
    }
  };

  const productDetail = async (productId) => {
    try {
      const response = await Get(
        `${URL_CONSTANTS.productDetails}/${productId}`
      );
      setProductItem(response.response);
      setselectedVarientsId(response?.response?.variants[0]?._id);
      setselectedImage(response?.response?.variants[0]?.images[0]);
      setselectedSizesId(response?.response?.variants[0]?.sizes[0]?._id);
      setLoader(false);
    } catch (error) {
      console.error("Error ", error.message);
    }
  };
  const productReview = async (productId) => {
    try {
      const response = await Get(`${URL_CONSTANTS.productReview}${productId}`);
      setAllReview(response.response);
    } catch (error) {
      console.error("Error ", error.message);
    }
  };
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
    },
  }));

  useEffect(() => {
    const cartItem = cartData?.find(
      (productData) => productData.sizeId === selectedSizesId
    );
    setis_CartExist(!!cartItem);
  }, [cartData, selectedSizesId]);
  useEffect(() => {
    const wishlistItem = wishlistData?.find(
      (productData) => productData.sizeId === selectedSizesId
    );
    setis_WishlistExist(!!wishlistItem);
  }, [wishlistData, selectedSizesId]);
  // Function to get the label for each rating
  const getRatingLabel = (rating) => {
    switch (rating) {
      case 5:
        return "Excellent";
      case 4:
        return "Good";
      case 3:
        return "Average";
      case 2:
        return "Below Average";
      case 1:
        return "Poor";
      default:
        return "";
    }
  };

  const ratingCategories = [5, 4, 3, 2, 1];

  // Calculate the count of reviews for each rating category
  const ratingCounts = ratingCategories.map((rating) => {
    return {
      rating,
      count: AllReview?.filter((review) => Math.floor(review.rating) === rating)
        ?.length,
    };
  });

  // Calculate total reviews for percentage calculation
  const totalReviews = AllReview?.length;

  const averageRating =
    totalReviews > 0
      ? (
          AllReview.reduce((sum, review) => sum + review.rating, 0) /
          totalReviews
        ).toFixed(1)
      : "0.0";

  return (
    <>
      {loader && <Loader />}
      <Modal
        open={openReviewImage}
        onClose={() => {
          setopenReviewImage(false);
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2, // Reduced padding
            outline: "none",
            borderRadius: "8px",
            maxWidth: "90%", // Max width to prevent too large of a modal
            maxHeight: "80vh", // Max height to make sure it's smaller
            overflow: "auto", // Scroll if needed
          }}
        >
          <img
            src={selectedImageReview}
            alt="Selected Review Image"
            style={{
              maxWidth: "100%",
              maxHeight: "70vh", // Limit height to prevent oversized images
              objectFit: "contain", // Maintain aspect ratio
            }}
          />
        </Box>
      </Modal>

      <Box className="mainWrapper">
        <Header />
        <Box className="detailSection">
          <div className="upper_section mb-20">
            <Grid container padding={5} columnSpacing={5}>
              <Grid lg={6} display={"flex"}>
                <div className="all_images">
                  {productItem?.variants
                    ?.find((variant) => variant._id === selectedVarientsId)
                    ?.images?.map((img) => {
                      return (
                        <img
                          className={`${
                            selectedImage === img
                              ? "active_img"
                              : "inActive_img"
                          }`}
                          src={img}
                          alt=""
                          onClick={() => {
                            setselectedImage(img);
                          }}
                        />
                      );
                    })}
                </div>
                <div className="big">
                  <Magnifier src={selectedImage} />
                </div>
              </Grid>
              <Grid lg={6} className="all_detail">
                <h1>{capitalizeFirstLetter(productItem?.title)}</h1>
                <h2>
                  {formatCurrency(
                    productItem?.variants
                      ?.find((variant) => variant._id === selectedVarientsId)
                      ?.sizes.find((size) => size._id === selectedSizesId)
                      ?.price ?? 0
                  )}
                  <span>
                    {formatCurrency(
                      productItem?.variants
                        ?.find((variant) => variant._id === selectedVarientsId)
                        ?.sizes.find((size) => size._id === selectedSizesId)
                        ?.mrp
                    )}
                  </span>
                </h2>
                <div className="colorss mb-6">
                  <h6>Select Color :</h6>
                  {productItem?.variants?.map((variant) => {
                    return (
                      <div
                        onClick={() => {
                          setselectedVarientsId(variant?._id);
                          setselectedSizesId(variant?.sizes[0]?._id);
                          setselectedImage(variant?.images[0]);
                        }}
                        className={`color_all ${
                          variant._id === selectedVarientsId ? "selected" : ""
                        }`}
                        style={{ background: variant?.color }}
                      ></div>
                    );
                  })}
                </div>
                <div className="sizes mb-6">
                  <h6>Size :</h6>
                  <>
                    {productItem?.variants
                      ?.find((variant) => variant._id === selectedVarientsId)
                      ?.sizes?.map((size) => {
                        return (
                          <div
                            onClick={() => {
                              setselectedSizesId(size._id);
                            }}
                            key={size._id}
                            className={`size_all ${
                              selectedSizesId === size._id ? "selected" : ""
                            }`}
                          >
                            {size.size}
                          </div>
                        );
                      })}
                  </>
                </div>
                <p className="desc">
                  {`${productItem?.description.substring(0, 250)}...`}
                </p>
                <div className="action">
                  <div className="ctaBtn text-center">
                    {is_WishlistExist ? (
                      <Button
                        onClick={() => {
                          deleteWishList(
                            productId,
                            selectedVarientsId,
                            selectedSizesId
                          );
                        }}
                        variant="contained"
                        className="borderBtn viewMore white"
                      >
                        Remove from Wishlist
                      </Button>
                    ) : (
                      <Button
                        onClick={addToWishlist}
                        variant="contained"
                        className="borderBtn viewMore white"
                      >
                        Add To Wishlist
                      </Button>
                    )}
                  </div>
                  <div className="ctaBtn text-center">
                    {is_CartExist ? (
                      <Button
                        onClick={removeFromCart}
                        variant="contained"
                        className="submitBtn"
                      >
                        Remove from Cart
                      </Button>
                    ) : productItem?.variants
                        ?.find((variant) => variant._id === selectedVarientsId)
                        ?.sizes.find((size) => size._id === selectedSizesId)
                        ?.quantity > 0 ? (
                      <Button
                        onClick={addToCart}
                        variant="contained"
                        className="submitBtn"
                      >
                        Add To Cart
                      </Button>
                    ) : (
                      <Button variant="contained" className="submitBtn">
                        Sold out
                      </Button>
                    )}
                  </div>
                </div>
                <div className="availability">
                  <div className="main">
                    <div className="main_image">
                      <img src="images/free.png" />
                    </div>
                    <div>
                      <p>Rapid Delivery</p>
                      <p className="value">3-4 days</p>
                    </div>
                  </div>
                  <div className="main">
                    <div className="main_image">
                      <img src="images/stock.png" />
                    </div>
                    <div>
                      <p>In Stock</p>
                      <p className="value">
                        {productItem?.variants
                          ?.find(
                            (variant) => variant._id === selectedVarientsId
                          )
                          ?.sizes.find((size) => size._id === selectedSizesId)
                          ?.quantity > 0
                          ? "Available"
                          : "Not Available"}
                      </p>
                    </div>
                  </div>
                  <div className="main">
                    <div className="main_image">
                      <img src="images/gaurantee.png" />
                    </div>
                    <div>
                      <p>Guarantee</p>
                      <p className="value">1 year</p>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="detail mb-20">
            <h2 className="head mb-3">Details</h2>
            <p className="head_p mb-6">
              {isExpanded
                ? productItem?.description
                : `${productItem?.description.substring(0, 1000)}...`}
            </p>
            <Grid xs={12} className="ctaBtn text-center">
              <Button
                onClick={toggleReadMore}
                variant="contained"
                className="borderBtn viewMore"
              >
                {isExpanded ? "View Less" : "View More"}{" "}
                {!isExpanded ? (
                  <KeyboardArrowDownIcon />
                ) : (
                  <KeyboardArrowUpIcon />
                )}
              </Button>
            </Grid>
          </div>
          <div className="reviews">
            <Grid container spacing={3}>
              <Grid lg={12}>
                <h2 className="head mb-3">Reviews</h2>
              </Grid>
              <Grid lg={12}>
                <div className="rate_section">
                  <div className="ratings">
                    <h3>{averageRating}</h3>
                    <p>of {totalReviews} reviews</p>
                    <Box component="fieldset" borderColor="transparent">
                      <Rating
                        name="read-only"
                        value={Number(averageRating)}
                        readOnly
                      />
                    </Box>
                  </div>
                  <div className="progress_div">
                    {ratingCounts.map((category) => (
                      <div className="progress" key={category.rating}>
                        <Typography variant="h6" gutterBottom>
                          {getRatingLabel(category.rating)}{" "}
                          {/* Function to get label like 'Excellent', 'Good', etc. */}
                        </Typography>
                        <Stack spacing={2} sx={{ flexGrow: 1 }}>
                          <BorderLinearProgress
                            variant="determinate"
                            value={(category.count / totalReviews) * 100}
                          />
                        </Stack>
                        <Typography variant="h5" gutterBottom>
                          {category.count}
                        </Typography>
                      </div>
                    ))}
                  </div>
                </div>
              </Grid>
              {/* <Grid lg={12}>
                <TextField label="Leave Comment" fullWidth />
              </Grid> */}
              {AllReview?.slice(0, visibleReviews).map((review) => (
                <Grid lg={12} className="comments mb-5">
                  <div>
                    <img className="userImg" src="images/userimg.png" />
                  </div>
                  <div className="cmnt_data">
                    <h4>
                      {capitalizeFirstLetter(
                        review.user.firstName + " " + review?.user?.lastName
                      )}
                    </h4>
                    <Rating
                      name="read-only"
                      value={Number(review?.rating)}
                      readOnly
                    />
                    <h6>{review?.comment}</h6>
                    <div className="cmnt_images">
                      {review.productImg.length > 0 &&
                        review?.productImg?.map((image) => (
                          <img
                            src={image?.imageUrl}
                            onClick={() => {
                              setSelectedImageReview(image?.imageUrl);
                              setopenReviewImage(true);
                            }}
                          />
                        ))}
                    </div>
                  </div>
                </Grid>
              ))}

              {visibleReviews < AllReview?.length && (
                <Grid xs={12} className="ctaBtn text-center mb-8">
                  <Button
                    onClick={handleShowMore}
                    variant="contained"
                    className="borderBtn viewMore white"
                  >
                    View More <KeyboardArrowDownIcon />
                  </Button>
                </Grid>
              )}
            </Grid>
          </div>
        </Box>
        <Footer />
      </Box>
    </>
  );
};

export default ProductDetail;
