import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { Get } from "../Api/api";
import { URL_CONSTANTS } from "../Api/ApiUrl";
import "../commonSCSS/common.scss";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { userRoutes } from "../Routes/UserRoutes";
import { capitalizeFirstLetter, formatCurrency, } from "../Supporting files/HelpingFunction";
import Loader from "./../components/Loader/Loader";

const DashboardData = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [newArrivals, setNewArrivals] = useState([]);
  const [newBlogs, setNewBlogs] = useState([]);
  const [featuredCollection, setFeaturedCollection] = useState([]);
  const [getAllProducts, setGetAllProducts] = useState([]);
  const [bestSellers, setBestSellers] = useState([]);
  const [selectedFeatureType, setselectedFeatureType] = useState("1");
  const [activeCat, setActiveCat] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchCategories();
    getNewArrival();
    getBestSeller();
    getFeaturedCollection();
    GetAllProduct();
    getBlog();
  }, []);

  const homeSliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const styleSliderSettings = {
    dots: true,
    // infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 5000,
    cssEase: "linear",
  };
  const testimonialSettings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0",
    slidesToShow: 3,
    autoplay: true,
    speed: 1000,
    dots: true,
  };
  //Get all category list
  const fetchCategories = async () => {
    try {
      const response = await Get(URL_CONSTANTS.getAllCategories);
      if (response.response?.length > 0) {
        setCategories(response.response);
        setActiveCat(0);
      }
    } catch (error) {
      console.error("Error ", error.message);
    }
  };

  //Get new arrival
  const getNewArrival = async () => {
    try {
      const response = await Get(URL_CONSTANTS.getNewArrival);
      setNewArrivals(response.response);
    } catch (error) {
      console.error("Error fetching getNewArrival:", error.message);
    }
  };
  //Get new blog
  const getBlog = async () => {
    try {
      const response = await Get(URL_CONSTANTS.getBlogs);
      setNewBlogs(response.response);
    } catch (error) {
      console.error("Error fetching Blogs:", error.message);
    }
  };
  //Get getBestSeller
  const getBestSeller = async () => {
    try {
      const response = await Get(URL_CONSTANTS.getBestSeller);
      setBestSellers(response.response.products);
    } catch (error) {
      console.error("Error fetching getNewArrival:", error.message);
    }
  };

  //Get getFeaturedCollection
  const getFeaturedCollection = async () => {
    try {
      const response = await Get(URL_CONSTANTS.getfeatured);
      setFeaturedCollection(response.response);
    } catch (error) {
      console.error("Error fetching getNewArrival:", error.message);
    }
  };

  //Get getAllProducts
  const GetAllProduct = async () => {
    try {
      const response = await Get(URL_CONSTANTS.getAllProducts);
      setGetAllProducts(response.response.products);
    } catch (error) {
      console.error("Error fetching getAllProducts:", error.message);
    }
  };

  const getSliderContent = () => {
    let data = [];
    switch (selectedFeatureType) {
      case "1":
        data = newArrivals;
        break;
      case "2":
        data = bestSellers;
        break;
      case "3":
        data = featuredCollection;
        break;
      default:
        data = [];
    }
    return (
      <Slider {...styleSliderSettings}>
        {data.length > 0 &&
          data.slice(-4).map((item, index) => (
            <Box
              key={index}
              className="img_slide"
              onClick={() => {
                if (selectedFeatureType === "2") {
                  navigate(userRoutes.bestSeller);
                } else {
                  navigate(userRoutes.newArrivals);
                }
              }}
            >
              <img
                className="slider_image"
                src={item.variants[0].images[0]}
                alt={`Style ${index + 1}`}
              />
              <div className="size_container">
                {item.variants.map((colors, sizeIndex) => (
                  <div
                    key={sizeIndex}
                    className="color_box"
                    style={{ backgroundColor: colors.color }}
                  ></div>
                ))}
              </div>
            </Box>
          ))}
      </Slider>
    );
  };

  return (
    <>
      {loading && <Loader />}
      <Box className="mainWrapper">
        <Header />
        <Box className="homeSlider mt-10">
          <Slider {...homeSliderSettings}>
            <Box>
              <Grid container>
                <Grid lg={6} className="slide_part1">
                  <Box>
                    <p className="slidHeading mb-6">
                      Falak Chanderi Handmade chikankari kurta
                    </p>
                    <Grid xs={12} className="ctaBtn">
                      <Button
                        onClick={() => {
                          navigate(userRoutes.products);
                        }}
                        variant="contained"
                        className="whiteBtn"
                      >
                        Shop Now
                      </Button>
                    </Grid>
                  </Box>
                </Grid>
                <Grid lg={6} className="slide_part2">
                  <img src="images/slide1_right.png" alt="1" />
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Grid container>
                <Grid lg={6} className="slide_part1">
                  <Box>
                    <p className="slidHeading mb-6">
                      Falak Chanderi Handmade chikankari kurta
                    </p>
                    <Grid xs={12} className="ctaBtn">
                      <Button variant="contained" className="submitBtn">
                        Shop Now
                      </Button>
                    </Grid>
                  </Box>
                </Grid>
                <Grid lg={6} className="slide_part2">
                  <img src="images/slide1_right.png" alt="1" />
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Grid container>
                <Grid lg={6} className="slide_part1">
                  <Box>
                    <p className="slidHeading mb-6">
                      Falak Chanderi Handmade chikankari kurta
                    </p>
                    <Grid xs={12} className="ctaBtn">
                      <Button variant="contained" className="submitBtn">
                        Shop Now
                      </Button>
                    </Grid>
                  </Box>
                </Grid>
                <Grid lg={6} className="slide_part2">
                  <img src="images/slide1_right.png" alt="1" />
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Grid container>
                <Grid lg={6} className="slide_part1">
                  <Box>
                    <p className="slidHeading mb-6">
                      Falak Chanderi Handmade chikankari kurta
                    </p>
                    <Grid xs={12} className="ctaBtn">
                      <Button variant="contained" className="submitBtn">
                        Shop Now
                      </Button>
                    </Grid>
                  </Box>
                </Grid>
                <Grid lg={6} className="slide_part2">
                  <img src="images/slide1_right.png" alt="1" />
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Grid container>
                <Grid lg={6} className="slide_part1">
                  <Box>
                    <p className="slidHeading mb-6">
                      Falak Chanderi Handmade chikankari kurta
                    </p>
                    <Grid xs={12} className="ctaBtn">
                      <Button variant="contained" className="submitBtn">
                        Shop Now
                      </Button>
                    </Grid>
                  </Box>
                </Grid>
                <Grid lg={6} className="slide_part2">
                  <img src="images/slide1_right.png" alt="1" />
                </Grid>
              </Grid>
            </Box>
          </Slider>
        </Box>
        <Box className="shopSection">
          <Box className="shop_box">
            <h2 className="theme_heading">
              Shop <span>By Categories</span>
            </h2>
            <Box marginBottom={8}>
              <Grid container spacing={3} className="category_container">
                <Grid xs={12}>
                  <div className="cat_scroller">
                    {categories?.length > 0 &&
                      categories?.map((categoryData, i) => (
                        <div
                          className="category pb-0"
                          key={categoryData?._id}
                          onClick={() => {
                            navigate(
                              `${userRoutes.products}?categoryId=${categoryData._id}`
                            );
                          }}
                        >
                          <img
                            onError={(e) => {
                              e.target.onError = null;
                              e.target.src = "images/dummy_category.jpg";
                            }}
                            className={`${activeCat === i ? "active" : ""}`}
                            onClick={() => setActiveCat(i)}
                            src={
                              categoryData.image || "images/dummy_category.jpg"
                            }
                            alt={categoryData.title}
                          />
                          <p>{categoryData.title}</p>
                        </div>
                      ))}
                  </div>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box className="style">
            <Grid container>
              <Grid item lg={3}>
                <h2 className="theme_heading_xl">
                  Style <span>Spotlight</span>
                </h2>
                <h5
                  className={
                    selectedFeatureType === "1" ? "highlight" : "normal"
                  }
                  style={{ position: "relative" }}
                  onClick={() => setselectedFeatureType("1")}
                >
                  New Arrivals
                </h5>
                <h5
                  className={
                    selectedFeatureType === "2" ? "highlight" : "normal"
                  }
                  style={{ position: "relative" }}
                  onClick={() => setselectedFeatureType("2")}
                >
                  Best Seller
                </h5>
                <h5
                  className={
                    selectedFeatureType === "3" ? "highlight" : "normal"
                  }
                  style={{ position: "relative" }}
                  onClick={() => setselectedFeatureType("3")}
                >
                  Featured Collection
                </h5>
                <Grid item xs={12} className="ctaBtn">
                  <Button
                    variant="contained"
                    className="submitBtn"
                    onClick={() => {
                      if (selectedFeatureType === "1") {
                        navigate(userRoutes.newArrivals);
                      } else if (selectedFeatureType === "2") {
                        navigate(userRoutes.bestSeller);
                      } else if (selectedFeatureType === "3") {
                        navigate(userRoutes.featuredProduts);
                      }
                    }}
                  >
                    View All
                  </Button>
                </Grid>
              </Grid>
              <Grid item lg={9} className="style_slider">
                {getSliderContent()}
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box className="lookSection">
          <Box className="look">
            <h2 className="theme_heading_xl pt-10 pb-10">
              Shop <span>The Look</span>
            </h2>
            <Box marginBottom={"80px"}>
              <Grid container className="look_container" rowSpacing={3}>
                {getAllProducts?.slice(-10)?.map((product) => (
                  <Grid
                    lg={2.4}
                    className="lookImg"
                    onClick={() => {
                      navigate(
                        `${userRoutes.productDetail}?productId=${product._id}`
                      );
                    }}
                  >
                    <img src={product.variants[0].images[0]} alt="look" />
                    <div className="desc">
                      <p>{product.title}</p>
                      <p>{formatCurrency(product.variants[0].sizes[0].mrp)}</p>
                    </div>
                  </Grid>
                ))}

                <Grid xs={12} className="ctaBtn text-center mt-6">
                  <Button
                    onClick={() => {
                      navigate(userRoutes.products);
                    }}
                    variant="contained"
                    className="submitBtn"
                  >
                    View All
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
        <Box className="blogSection">
          <h2 className="theme_heading">
            From The <span>Blog</span>
          </h2>
          <Box>
            <Grid container spacing={3}>
              {newBlogs.slice(-3).map((data) => (
                <Grid lg={4} className="blog">
                  <div className="date"> Feb/2/2024 </div>
                  <img src={data.image} alt="blog" />
                  <div className="info p-6">
                    <h6>{data.title.toUpperCase(3)}</h6>
                    <p>
                      {data.description?.length > 200
                        ? capitalizeFirstLetter(
                            data.description.slice(0, 200)
                          ) + "..."
                        : capitalizeFirstLetter(data.description)}
                    </p>{" "}
                    <Grid xs={12} className="ctaBtn p-0">
                      <Button
                        variant="contained"
                        className="borderBtn"
                        onClick={() => navigate(userRoutes.blogDetail)}
                      >
                        Read More
                      </Button>
                    </Grid>
                  </div>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
        <Box className="followSection">
          <h2 className="theme_heading">
            Follow Us On <span>Instagram</span>
          </h2>
          <Box>
            <Grid container columnSpacing={4} rowSpacing={3}>
              <Grid xs={12} sm={6} md={4} lg={3} className="follow">
                <div className="logo">
                  <img src="images/instaLogo.png" alt="insta" />
                </div>
                <img
                  src="images/follow1.svg"
                  alt="insta"
                  className="follow-img"
                />
              </Grid>
              <Grid xs={12} sm={6} md={4} lg={3} className="follow">
                <div className="logo">
                  <img src="images/instaLogo.png" alt="insta" />
                </div>
                <img
                  src="images/follow2.svg"
                  alt="insta"
                  className="follow-img"
                />
              </Grid>
              <Grid xs={12} sm={6} md={4} lg={3} className="follow">
                <div className="logo">
                  <img src="images/instaLogo.png" alt="insta" />
                </div>
                <img
                  src="images/follow1.svg"
                  alt="insta"
                  className="follow-img"
                />
              </Grid>
              <Grid xs={12} sm={6} md={4} lg={3} className="follow">
                <div className="logo">
                  <img src="images/instaLogo.png" alt="insta" />
                </div>
                <img
                  src="images/follow2.svg"
                  alt="insta"
                  className="follow-img"
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box className="testimonialSection">
          <h2 className="theme_heading mb-0">Testimonials</h2>
          <Slider {...testimonialSettings}>
            <Box className="testBox">
              <Grid container columnSpacing={3} paddingLeft={"12px"}>
                <Grid lg={4} className="user">
                  <img src="images/james1.svg" alt="james" />
                </Grid>
                <Grid lg={8} className="userData pl-0">
                  <p>
                    Absolutely thrilled with my shopping experience! The user
                    interface is seamless, and the customer service is
                    outstanding. Highly recommend this e-commerce platform!
                  </p>
                  <div className="stars">
                    <img src="images/star.svg" />
                    <img src="images/star.svg" />
                    <img src="images/star.svg" />
                    <img src="images/star.svg" />
                  </div>
                  <div className="devider"></div>
                  <h2>James K.1</h2>
                </Grid>
              </Grid>
            </Box>
            <Box className="testBox">
              <Grid container columnSpacing={3} paddingLeft={"12px"}>
                <Grid lg={4} className="user">
                  <img src="images/james2.svg" alt="james" />
                </Grid>
                <Grid lg={8} className="userData pl-0">
                  <p>
                    Absolutely thrilled with my shopping experience! The user
                    interface is seamless, and the customer service is
                    outstanding. Highly recommend this e-commerce platform!
                  </p>
                  <div className="stars">
                    <img src="images/star.svg" />
                    <img src="images/star.svg" />
                    <img src="images/star.svg" />
                    <img src="images/star.svg" />
                  </div>
                  <div className="devider"></div>
                  <h2>James K.2</h2>
                </Grid>
              </Grid>
            </Box>
            <Box className="testBox">
              <Grid container columnSpacing={3} paddingLeft={"12px"}>
                <Grid lg={4} className="user">
                  <img src="images/james1.svg" alt="james" />
                </Grid>
                <Grid lg={8} className="userData pl-0">
                  <p>
                    Absolutely thrilled with my shopping experience! The user
                    interface is seamless, and the customer service is
                    outstanding. Highly recommend this e-commerce platform!
                  </p>
                  <div className="stars">
                    <img src="images/star.svg" />
                    <img src="images/star.svg" />
                    <img src="images/star.svg" />
                    <img src="images/star.svg" />
                  </div>
                  <div className="devider"></div>
                  <h2>James K.3</h2>
                </Grid>
              </Grid>
            </Box>
            <Box className="testBox">
              <Grid container columnSpacing={3} paddingLeft={"12px"}>
                <Grid lg={4} className="user">
                  <img src="images/james2.svg" alt="james" />
                </Grid>
                <Grid lg={8} className="userData pl-0">
                  <p>
                    Absolutely thrilled with my shopping experience! The user
                    interface is seamless, and the customer service is
                    outstanding. Highly recommend this e-commerce platform!
                  </p>
                  <div className="stars">
                    <img src="images/star.svg" />
                    <img src="images/star.svg" />
                    <img src="images/star.svg" />
                    <img src="images/star.svg" />
                  </div>
                  <div className="devider"></div>
                  <h2>James K.4</h2>
                </Grid>
              </Grid>
            </Box>
          </Slider>
        </Box>
        <Footer />
      </Box>
    </>
  );
};

export default DashboardData;
