import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "../commonSCSS/common.scss";
import Footer from "../components/Footer";
import Header from "../components/Header";
import SDTextField from "../Supporting files/SDTextField/SDTextField";
import TextArea from "../Supporting files/TextArea/TextArea";

const ContactUs = () => {
    return (
        <>
            <Box className="mainWrapper">
                <Header />
                <Box className="our_blogs contact_page">
                    <Box>
                        <h1>Contact Us</h1>
                        <h2>
                            Home/<span> Contact Us</span>
                        </h2>
                    </Box>
                    <Box className="contactSection">
                        <Grid container padding={0}>
                            <Grid xs={12} className="imgPart mb-20">
                                <img className="abtImg w-100" src="images/abtus.svg" alt="about" />
                                <h2>We would love to hear from you</h2>
                            </Grid>
                            <Grid xs={12} className="touch">
                                <Box>
                                    <Grid container padding={0}>
                                        <Grid xs={12} className="touchHead mb-6">
                                        <h2 className="h2">Get In touch</h2>
                                        </Grid>
                                        <Grid xs={12} lg={6} className="touchimg">
                                            <img className="w-100" src="images/getintouch.svg" />
                                        </Grid>
                                        <Grid  xs={12} lg={6} className="touchForm">
                                            <Box>
                                                <Grid container spacing={3}>
                                                    <Grid xs={12} md={6}>
                                                        <SDTextField
                                                            title="Name"
                                                            value={""}
                                                            attrName={"name"}
                                                            value_update={() => { }}
                                                            cls="w-100"
                                                        />
                                                    </Grid>
                                                    <Grid xs={12} md={6}>
                                                        <SDTextField
                                                            title="Email"
                                                            value={""}
                                                            attrName={"name"}
                                                            value_update={() => { }}
                                                            cls="w-100"
                                                        />
                                                    </Grid>
                                                    <Grid xs={12}>
                                                        <SDTextField
                                                            title="Phone Number"
                                                            value={""}
                                                            attrName={"name"}
                                                            value_update={() => { }}
                                                            cls="w-100"
                                                        />
                                                    </Grid>
                                                    <Grid xs={12}>
                                                        <TextArea
                                                            title={"Message"}
                                                            value_update={() => { }}
                                                            attrName={[""]}
                                                            warn_status={false}
                                                            class_name={"inputTextfield"}
                                                            placeholder={'Message'}
                                                        />
                                                    </Grid>
                                                    <Grid xs={12}>
                                                        <h5 className="msg">This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply.</h5>
                                                    </Grid>
                                                    <Grid xs={12} className="ctaBtn text-left">
                                                        <Button
                                                            variant="contained"
                                                            className="submitBtn"
                                                        >
                                                            Send
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>

                <Footer />
            </Box>
        </>
    );
};

export default ContactUs;