import { format } from "date-fns";

export const formatCurrency = (num) => {
  // Convert to number in case the input is a string
  num = Number(num);

  // Use the Intl.NumberFormat API to format the number
  const formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
  });

  return formatter.format(num);
};

export const capitalizeFirstLetter = (str) => {
  if (typeof str !== "string" || str.length === 0) {
    return "";
  }

  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const formatDateDDMMYYYY = (dateString)=> {
  return format(new Date(dateString), "dd-MM-yyyy hh:mm a");
}

export function formatDate(dateString) {
  const date = new Date(dateString);
  const options = { weekday: "short", day: "numeric", month: "long" };
  return date
    .toLocaleDateString("en-US", options)
    .replace(/,/g, "")
    .replace(/,/, ",");
}
export function formatOrderStatus(status) {
  return status
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export function truncateDescription(description, limit) {
  if (description.length > limit) {
    return description.slice(0, limit) + "...";
  }
  return description;
}
