import React, { useState } from "react";
import { Post } from "../Api/api";
import { ADMINURL_CONSTANTS } from "./AdminApiUrl/AdminApiUrl";
import toast from "react-hot-toast";
import { setAdmindata } from "../Redux/Slice/AdminSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader/Loader";
import Cookies from "js-cookie";

const AdminLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError("");
    setLoading(true);

    const loginData = {
      email,
      password,
    };

    try {
      const response = await Post(
        ADMINURL_CONSTANTS.login,
        JSON.stringify(loginData)
      );
      const { token } = response.response;
      dispatch(setAdmindata(response.response));
      setLoading(false);
      window.location.href = "/admin/dashboard";
      Cookies.set("ishnooradminToken", token, { expires: 1 });
      toast.success("Logged in successfully");
    } catch (error) {
      setError(error.response.data.message);
      console.error("Error logging in:", error.response.data.message);
    }
  };

  const styles = {
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      background: "linear-gradient(to right, #6a11cb, #2575fc)",
      padding: "1rem",
    },
    card: {
      backgroundColor: "white",
      padding: "2rem",
      borderRadius: "10px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      width: "100%",
      maxWidth: "500px",
      textAlign: "center",
    },
    title: {
      marginBottom: "1.5rem",
      color: "#333",
    },
    formGroup: {
      marginBottom: "1.5rem",
      textAlign: "left",
    },
    label: {
      display: "block",
      marginBottom: "0.5rem",
      color: "#666",
    },
    input: {
      width: "100%",
      padding: "0.75rem",
      border: "1px solid #ccc",
      borderRadius: "5px",
    },
    errorMessage: {
      color: "red",
      marginBottom: "1rem",
    },
    button: {
      width: "100%",
      padding: "0.75rem",
      border: "none",
      borderRadius: "5px",
      backgroundColor: "#6a11cb",
      color: "white",
      fontSize: "1rem",
      cursor: "pointer",
      transition: "background-color 0.3s ease",
    },
    buttonHover: {
      backgroundColor: "#2575fc",
    },
  };

  return (
    <>
      {loading && <Loader />}

      <div style={styles.container}>
        <div style={styles.card}>
          <h2 style={styles.title}>Admin Login</h2>
          <form onSubmit={handleSubmit}>
            <div style={styles.formGroup}>
              <label htmlFor="email" style={styles.label}>
                Email:
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                style={styles.input}
              />
            </div>
            <div style={styles.formGroup}>
              <label htmlFor="password" style={styles.label}>
                Password:
              </label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                style={styles.input}
              />
            </div>
            {error && <div style={styles.errorMessage}>{error}</div>}
            <button
              type="submit"
              style={styles.button}
              onMouseOver={(e) =>
                (e.currentTarget.style.backgroundColor =
                  styles.buttonHover.backgroundColor)
              }
              onMouseOut={(e) =>
                (e.currentTarget.style.backgroundColor =
                  styles.button.backgroundColor)
              }
            >
              Login
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default AdminLogin;
