import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Get, Post } from "../Api/api";
import { URL_CONSTANTS } from "../Api/ApiUrl";
import "../commonSCSS/header.scss";
import { userRoutes } from "../Routes/UserRoutes";

const Footer = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetchCategories();
  }, []);

  //Get all category list
  const fetchCategories = async () => {
    try {
      const response = await Get(URL_CONSTANTS.getAllCategories);
      if (response.response?.length > 0) {
        setCategories(response.response);
      }
    } catch (error) {
      console.error("Error ", error.message);
    }
  };

  const checkoutOrder = async () => {
    let payload = { email };
    try {
      const response = await Post(
        `${URL_CONSTANTS.newsLetter}`,
        JSON.stringify(payload)
      );
      setEmail("");
      toast.success("Subscribed successfully");
    } catch (error) {
      console.error("Error ", error.message);
    }
  };
  return (
    <>
      <div className="bg_image"></div>
      <Box className="userFooter">
        <Grid container spacing={3}>
          <Grid xs={12} sm={6} lg={3} className="footer_grid">
            <img onClick={()=>navigate(userRoutes.dashboard)} src="/images/logo.png" alt="logo" className="mb-6" />
            <p onClick={()=>navigate(userRoutes.aboutUs)}>About US</p>
            <p onClick={()=>navigate(userRoutes.contactUs)}>Contact Us</p>
            <p onClick={()=>navigate(userRoutes.blogs)}>Blog</p>
            {/* <p onClick={()=>navigate(userRoutes.faq)}>FAQ</p> */}
            <p>Out Store</p>
          </Grid>
          <Grid xs={12} sm={6} lg={3} className="footer_grid">
            <h2 className="mb-6">SHOP BY CATEGORIES</h2>
            {categories.slice(-5).map((cat, index) => (
              <p
                key={index}
                onClick={() => {
                  navigate(`${userRoutes.products}?categoryId=${cat._id}`);
                }}
              >
                {cat?.title}
              </p>
            ))}
          </Grid>
          <Grid xs={12} sm={6} lg={3} className="footer_grid">
            <h2 className="mb-6">HELP</h2>
            <p onClick={()=>navigate(userRoutes.privacy)}>Privacy Policy </p>
            <p onClick={()=>navigate(userRoutes.terms)}>Terms of Service </p>
            <p onClick={()=>navigate(userRoutes.returnExchange)}>Return & Exchange Policy </p>
            <p onClick={()=>navigate(userRoutes.returnRequest)}>Return/Exchange Request </p>
            <p onClick={()=>navigate(userRoutes.shipping)}>Shipping </p>
          </Grid>
          <Grid xs={12} sm={6} lg={3} className="footer_grid">
            <h2 className="mb-6">Subscribe Newsletter</h2>
            <p className="para mb-6">
              Stay in touch for the latest exclusive offers...
            </p>
            <div className="emailaddr">
              <input
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                className="mb-6"
              />
              <img src="images/footer_email.svg" />
            </div>
            <Grid xs={12} className="ctaBtn p-0">
              <Button
                variant="contained"
                className="submitBtn"
                onClick={checkoutOrder}
              >
                Subscribe <span className="icon">{<ArrowForwardIcon />}</span>
              </Button>
            </Grid>
          </Grid>
          <Grid xs={12} className="footer_grid p-0">
            <hr className="divider" />
          </Grid>
          <Grid xs={12} padding={0}>
            <Box className="copy">
              <p className="copyright">
                © Copyright {new Date().getFullYear()} | www.isnoor.in | All
                Rights Reserved | Developed by{" "}
                <span
                  onClick={() => {
                    window.open("https://vicancode.com/", "_blank");
                  }}
                >
                  VicanCode
                </span>
              </p>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default Footer;
