import React, { useRef, useEffect } from "react";

const Magnifier = ({ src }) => {
  const imgRef = useRef(null);
  const glassRef = useRef(null);

  useEffect(() => {
    const img = imgRef.current;
    const glass = glassRef.current;
    const zoom = 3; // Adjust zoom level as needed

    glass.style.backgroundImage = `url('${src}')`;
    glass.style.backgroundSize = `${img.width * zoom}px ${img.height * zoom}px`;

    const moveMagnifier = (e) => {
      e.preventDefault();
      const pos = getCursorPos(e);
      let x = pos.x;
      let y = pos.y;
      const glassWidth = glass.offsetWidth;
      const glassHeight = glass.offsetHeight;

      // Adjust x and y to ensure the entire image is zoomable within the magnifier
      x = Math.max(glassWidth / 2, Math.min(img.width - glassWidth / 2, x));
      y = Math.max(glassHeight / 2, Math.min(img.height - glassHeight / 2, y));

      glass.style.left = `${x - glassWidth / 2}px`;
      glass.style.top = `${y - glassHeight / 2}px`;
      glass.style.backgroundPosition = `-${(x - glassWidth / 2) * zoom}px -${
        (y - glassHeight / 2) * zoom
      }px`;
    };

    const getCursorPos = (e) => {
      const rect = img.getBoundingClientRect();
      const x = e.pageX - rect.left - window.pageXOffset;
      const y = e.pageY - rect.top - window.pageYOffset;
      return { x, y };
    };

    const showGlass = () => {
      glass.style.display = "block";
    };
    const hideGlass = () => {
      glass.style.display = "none";
    };

    img.addEventListener("mousemove", moveMagnifier);
    img.addEventListener("mouseover", showGlass);
    img.addEventListener("mouseout", hideGlass);
    glass.addEventListener("mousemove", moveMagnifier);
    glass.addEventListener("mouseover", showGlass);
    glass.addEventListener("mouseout", hideGlass);

    return () => {
      img.removeEventListener("mousemove", moveMagnifier);
      img.removeEventListener("mouseover", showGlass);
      img.removeEventListener("mouseout", hideGlass);
      glass.removeEventListener("mousemove", moveMagnifier);
      glass.removeEventListener("mouseover", showGlass);
      glass.removeEventListener("mouseout", hideGlass);
    };
  }, [src]);

  return (
    <div
      className="magnifier-container"
      style={{
        position: "relative",
        width: "400px",
        height: "500px",
        overflow: "hidden",
      }}
    >
      <img
        src={src}
        alt="Product"
        ref={imgRef}
        style={{ width: "100%", height: "100%", objectFit: "cover" }}
      />
      <div
        className="magnifier-glass"
        ref={glassRef}
        style={{
          position: "absolute",
          border: "3px solid #000",
          cursor: "none",
          width: "150px", // Adjust the width of the magnifier lens
          height: "150px", // Adjust the height of the magnifier lens
          backgroundColor: "rgba(255, 255, 255, 0.5)",
          backgroundRepeat: "no-repeat",
          display: "none",
        }}
      ></div>
    </div>
  );
};

export default Magnifier;
