import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "../commonSCSS/common.scss";
import Footer from "../components/Footer";
import Header from "../components/Header";

const ReturnExchange = () => {
    return (
        <>
            <Box className="mainWrapper">
                <Header />
                <Box className="our_blogs privacy">
                    <Box>
                        <h1>Return & Exchange Policy</h1>
                        <h2>
                            Home/<span> Return & Exchange Policy</span>
                        </h2>
                    </Box>
                    <Box className="privacySection">
                        <Grid container padding={0}>
                            <Grid xs={12} className="mb-6">
                                <h3 className="para mb-3">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                </h3>
                                <h3 className="para mb-3">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                </h3>
                            </Grid>
                            <Grid xs={12} className="mb-6">
                                <h3 className="para_heading mb-3">
                                    1.  Lorem Ipsum is simply dummy
                                </h3>
                                <h3 className="para mb-3">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                </h3>
                                <h3 className="para mb-3">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                </h3>
                                <h3 className="para mb-3">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                </h3>
                                <h3 className="para mb-3">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                </h3>
                            </Grid>
                            <Grid xs={12} className="mb-6">
                                <h3 className="para_heading mb-3">
                                    2.  Lorem Ipsum is simply dummy
                                </h3>
                                <h3 className="para mb-3">
                                    We may use your Personal Information for the following purposes:
                                </h3>

                                <h3 className="point mb-3 pl-15">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                </h3>
                                <h3 className="point mb-3 pl-15">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                </h3>
                                <h3 className="point mb-3 pl-15">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                </h3>
                            </Grid>
                            <Grid xs={12} className="mb-6">
                                <h3 className="para_heading mb-3">
                                    3.  Lorem Ipsum is simply dummy
                                </h3>
                                <h3 className="para mb-3">
                                    We may use your Personal Information for the following purposes:
                                </h3>
                            </Grid>
                            <Grid xs={12} className="mb-6">
                                <h3 className="para_heading mb-3">
                                    4.  Lorem Ipsum is simply dummy
                                </h3>
                                <h3 className="para mb-3">
                                    We may use your Personal Information for the following purposes:
                                </h3>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Footer />
            </Box>
        </>
    );
};

export default ReturnExchange;