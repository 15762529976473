import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "../commonSCSS/common.scss";
import Footer from "../components/Footer";
import Header from "../components/Header";
import SDTextField from "../Supporting files/SDTextField/SDTextField";

const ReturnRequest = () => {
    return (
        <>
            <Box className="mainWrapper">
                <Header />
                <Box className="our_blogs contact_page">
                    <Box className="contactSection">
                        <Box className="touch">
                            <Grid container padding={0}>
                                <Grid xs={12} className="touchHead mb-6">
                                    <h2 className="h2 mb-4">Return/Exchange Request</h2>
                                    <p className="ptag">Enter your order number and Email or Phone to find your order</p>
                                </Grid>
                                <Grid xs={12} lg={6} className="touchimg mb-3">
                                    <img className="w-100" src="images/getintouch.svg" />
                                </Grid>
                                <Grid xs={12} lg={6} className="touchForm">
                                    <Box>
                                        <Grid container spacing={3}>
                                            <Grid xs={12}>
                                                <SDTextField
                                                    title="Order Number"
                                                    value={""}
                                                    attrName={"name"}
                                                    value_update={() => { }}
                                                    cls="w-100"
                                                />
                                            </Grid>
                                            <Grid xs={12}>
                                                <SDTextField
                                                    title="Email or Phone Number"
                                                    value={""}
                                                    attrName={"name"}
                                                    value_update={() => { }}
                                                    cls="w-100"
                                                />
                                            </Grid>
                                            <Grid xs={12}>
                                                <h5 className="msgB">Return & Exchange Policy</h5>
                                                <h5 className="msgB2">Please remember: don't hand over products to delivery until the pick-up message is received. Make a video/picture during the handover. We arrange pick-ups via 3rd-party courier. House of Kari is not liable if handed to the wrong person.</h5>
                                            </Grid>
                                            <Grid xs={12} className="ctaBtn text-left">
                                                <Button
                                                    variant="contained"
                                                    className="submitBtn"
                                                >
                                                    Find Your Order
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
                <Footer />
            </Box>
        </>
    );
};

export default ReturnRequest;