// store.js
import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import userReducer from "./Redux/Slice/UserSlice";
import adminReducer from "./Redux/Slice/AdminSlice";
import { combineReducers } from "redux";

const persistConfig = {
  key: "persist:root",
  storage,
};

const rootReducer = combineReducers({
  user: userReducer,
  admin: adminReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
