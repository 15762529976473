import { Box, Modal } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import CircularProgress from '@mui/material/CircularProgress';
import '../../commonSCSS/modals.scss';

const PasswordSuccess = ({ pwSuccess, setPwSuccess }) => {
    return (
        <>
            <Modal open={pwSuccess} className="modalWrapper" >
                <Box className="modalInner p-0" maxWidth={435}>
                    <Grid container textAlign={'center'} className="px-9 py-6">
                        <Grid xs={12} className="title mb-12">
                            <img src='/images/pwSuccess.png' alt='l' />
                        </Grid>
                        <Grid xs={12}>
                            <h1 className="success">Success</h1>
                        </Grid>
                        <Grid xs={12}>
                            <p className="msg">Password changed successfully, you will be redirected to the Home in a few seconds.</p>
                        </Grid>
                        <Grid xs={12} className="loading">
                            <CircularProgress disableShrink className='loader' />
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </>
    )
}

export default PasswordSuccess
