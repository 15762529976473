import { Box, Button, Grid } from '@mui/material';
import { useState } from 'react';
import SDTextField from '../Supporting files/SDTextField/SDTextField';
import PasswordSuccess from '../components/Modals/PasswordSuccess';

const ForgotPassword = () => {
    const [step, setStep] = useState(1);
    const [email, setEmail] = useState("");
    const [pwSuccess, setPwSuccess] = useState(false);

    const updateMaster = (attr, value) => {
        attr(value);
    }
    const submit = () => {
        if (step === 3) { return setPwSuccess(true); }
        setStep(step + 1)
    }

    return (
        <>
            <PasswordSuccess
                pwSuccess={pwSuccess}
                setPwSuccess={setPwSuccess}
            />
            <Box className="">
                {/* <Box className="mainWrapper"> */}
                <div className='login_screen'>
                    <div className='login'>
                        <Box>
                            <Grid container padding={0} textAlign={'center'} >
                                <Grid xs={12} className="mb-7">
                                    <img src='/images/logo.png' alt='logo' />
                                </Grid>
                                <Grid xs={12} className="mb-7">
                                    <h1 className='welcome'>{step === 1 ?
                                        "Forgot Password" : step === 2 ?
                                            "Enter Code" : "Create New Password"}</h1>
                                </Grid>
                                {step === 1 &&
                                    <Grid xs={12} className="mb-6">
                                        <SDTextField
                                            title="Email Address"
                                            value={email}
                                            attrName={setEmail}
                                            value_update={updateMaster}
                                            cls="w-100"
                                        />
                                    </Grid>}
                                {step === 2 &&
                                    <Grid xs={12} className="mb-6">
                                        <SDTextField
                                            title="Enter Code"
                                            value={email}
                                            attrName={setEmail}
                                            value_update={updateMaster}
                                            cls="w-100"
                                        />
                                    </Grid>}
                                {step === 3 &&
                                    <>
                                        <Grid xs={12} className="mb-6">
                                            <SDTextField
                                                title="New Password"
                                                value={email}
                                                attrName={setEmail}
                                                value_update={updateMaster}
                                                cls="w-100"
                                            />
                                        </Grid>
                                        <Grid xs={12} className="mb-6">
                                            <SDTextField
                                                title="Confirm Password"
                                                value={email}
                                                attrName={setEmail}
                                                value_update={updateMaster}
                                                cls="w-100"
                                            />
                                        </Grid>
                                    </>
                                }

                                <Grid xs={12} className="ctaBtn mb-6">
                                    <Button className="submitBtn w-100"
                                        onClick={submit}>
                                        {step === 1 ?
                                            "Continue" : "Verify"}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </div>
                </div>
            </Box>
        </>
    )
}

export default ForgotPassword
