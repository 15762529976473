import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "../commonSCSS/common.scss";
import Header from "../components/Header";
import Footer from "../components/Footer";

const BlogDetail = () => {
  return (
    <>
      <Box className="mainWrapper">
        <Header />
        <Box className="blog_detailSection">
          <h4 className="heading mb-10">
            CHIKANKARI KURTA SETS FOR MOTHER'S DAY SPECIAL
          </h4>
          <Grid container padding={0}>
            <Grid lg={12}>
              <img className="blog_img mb-6" src="/images/blg.png" alt="blg" />
            </Grid>
            <Grid className="blog_text" lg={12}>
              <p className="mb-3">
                Independence Day is a day of great honor and pride for all of us
                Indians. It is a great feeling to see the whole country come
                forward to honor the sacrifices of our freedom fighters and
                national leaders. What better way to celebrate this day than to
                take a trip down memory lane and see that our traditional art
                adds an ethnic charm to the celebrations of Independence Day
                2024? One of the best ways to celebrate this day is to bring
                together various Independence Day Special Outfit ideas that
                represent our Indian heritage. When we talk about Indian
                heritage, it is very difficult to miss the Chikankari art form.
                This delicate embroidery technique has had a very high fan
                following in the Indian subcontinent since the Mughal era.
              </p>
              <h4>Chikankari Fashion for Independence Day</h4>
              <p className="para">
                India will be completing 78 years as an independent nation on
                August 15, 2024, and there is no better way to show our
                patriotism than by wearing our favorite Ethnic Outfits, which
                are not only popular in our country but also around the
                world. Independence Day is the proper time to take out your
                bright saffrons, peaceful whites, and gorgeous greens. Desi
                vibes surround the air and women can be seen donning the desi
                avatar while western outfits take a backseat.
              </p>
              <p className="para">
                If you are interested in wearing some of the best contemporary
                and royal Chikankari clothing, Independence Day is the best
                time. Chikankari kurtas can be a great option if you want to
                look graceful and elegant. Chikankari kurta sets are now
                available in a range of colors, such as orange, green, and blue,
                so you can choose your favorite one. The elegance of Women’s
                Chikankari kurtas lies in their timeless appeal, which offers
                endless opportunities for those incorporating both tradition and
                trend in their outfit. This Independence Day, highlight your
                patriotism through ethnic outfits from the House of Kari.
              </p>
            </Grid>
          </Grid>
        </Box>
        <Footer />
      </Box>
    </>
  );
};

export default BlogDetail;
