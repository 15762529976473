import React from "react";
import { Outlet } from "react-router-dom";
import NavigationMenu from "../Admin/NavigationMenu";
import Cookies from "js-cookie";

const AdminLayout = () => {
  return (
    <>
    {Cookies.get("ishnooradminToken") &&
      <NavigationMenu />
    }
      <Outlet />
    </>
  );
};

export default AdminLayout;
