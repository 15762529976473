import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Footer from "../components/Footer";
import Slider from "@mui/material/Slider";
import { useEffect, useState } from "react";
import { FormControlLabel, Checkbox } from "@mui/material";
import SDTextField from "../Supporting files/SDTextField/SDTextField";
import { userRoutes } from "../Routes/UserRoutes";
import { URL_CONSTANTS } from "../Api/ApiUrl";
import { Get, Post, Put } from "../Api/api";
import {
  capitalizeFirstLetter,
  formatCurrency,
} from "../Supporting files/HelpingFunction";
import toast from "react-hot-toast";
import Loader from "../components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { updateCart } from "../Redux/Slice/UserSlice";

function valuetext(value) {
  return `${value}°C`;
}

const ProductsData = () => {
  const cartData = useSelector((state) => state.user.cart);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const categoryId = searchParams.get("categoryId");
  const [value, setValue] = useState([0, 5000]);
  const [productStats, setproductStats] = useState([]);
  const [checkedCategories, setCheckedCategories] = useState([]);
  const [selectedColors, setSelectedColors] = useState([]);
  const [allProducts, setallProducts] = useState([]);
  const [isLoader, setIsLoader] = useState(true);

  useEffect(() => {
    let catId = [...checkedCategories];
    catId.push(categoryId);
    setCheckedCategories(catId);
  }, [categoryId]);

  const handleCheckboxChange = (id) => {
    setCheckedCategories((prevCheckedCategories) => {
      const newCheckedCategories = prevCheckedCategories.includes(id)
        ? prevCheckedCategories.filter((categoryId) => categoryId !== id)
        : [...prevCheckedCategories, id];
      return newCheckedCategories;
    });
  };

  const handleColorClick = (color) => {
    setSelectedColors((prevSelectedColors) =>
      prevSelectedColors.includes(color)
        ? prevSelectedColors.filter((selectedColor) => selectedColor !== color)
        : [...prevSelectedColors, color]
    );
  };
  const addToCart = async (productId, variantId, sizeId) => {
    let dto = {
      productId,
      variantId,
      sizeId,
      quantity: 1,
    };
    try {
      const response = await Post(
        `${URL_CONSTANTS.AddCartItems}`,
        JSON.stringify(dto)
      );
      dispatch(updateCart(response.response.products));

      toast.success("Cart updated successfully");
    } catch (error) {
      console.error("Error ", error);
    }
  };

  const removeFromCart = async (productId, variantId, sizeId) => {
    let dto = {
      productId,
      variantId,
      sizeId,
      quantity: 1,
    };
    try {
      const response = await Put(
        `${URL_CONSTANTS.removeCartItems}`,
        JSON.stringify(dto)
      );
      dispatch(updateCart(response.response.products));
      toast.success("Cart updated successfully");
    } catch (error) {
      console.error("Error ", error);
    }
  };
  useEffect(() => {
    GetProductStates();
    //GetAllProduct();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const updateMaster = (attr, value) => {
    setValue(value);
  };

  const GetProductStates = async () => {
    try {
      const response = await Get(URL_CONSTANTS.getProductState);
      setValue([0, response.response.maxPrice]);
      setproductStats(response.response);
    } catch (error) {
      console.error("Error fetching getAllProducts:", error.message);
    }
  };
  const GetAllProduct = async () => {
    try {
      const categories =
        checkedCategories.length > 0 ? checkedCategories.join(",") : "";
      const colors = selectedColors.map(encodeURIComponent).join(",");
      const min = value[0];
      const max = value[1];
      let query = `?min=${min}&max=${max}`;

      if (categories && categories.length > 1) {
        query += `&categories=${categories}`;
      }
      if (colors && colors.length > 0) {
        query += `&colors=${colors}`;
      }
      const url = `${URL_CONSTANTS.getAllProducts}${query}`;

      const response = await Get(url);
      setallProducts(response.response.products);
      setIsLoader(false);
    } catch (error) {
      console.error("Error fetching getAllProducts:", error.message);
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      GetAllProduct();
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [checkedCategories, selectedColors, value]);
  return (
    <>
      {isLoader && <Loader />}
      <Box className="mainWrapper">
        <Header />
        <Box className="listSection">
          <Grid container columnSpacing={3}>
            <Grid lg={3} className="filter_accordians">
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3-content"
                  id="panel3-header"
                  className="accr_header"
                >
                  <h2 className="heading">Price</h2>
                </AccordionSummary>
                <AccordionDetails>
                  <Box className="panel_content">
                    <div className="pricing mt-6">
                      <p>From</p>
                      <p>To</p>
                    </div>
                    <div className="pricing mt-2 mb-4">
                      <SDTextField
                        title=""
                        value={value[0]}
                        value_update={() => {}}
                        cls="w-100"
                        style={{ maxWidth: "100px" }}
                        disable={true}
                      />
                      <div className="seprator"></div>
                      <SDTextField
                        title=""
                        value={value[1]}
                        value_update={() => {}}
                        cls="w-100"
                        style={{ maxWidth: "100px" }}
                        disable={true}
                      />
                    </div>
                    <Slider
                      className="pricePicker"
                      getAriaLabel={() => "Temperature range"}
                      value={value}
                      onChange={handleChange}
                      valueLabelDisplay="auto"
                      getAriaValueText={valuetext}
                      max={+productStats?.maxPrice}
                      min={0}
                    />
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3-content"
                  id="panel3-header"
                  className="accr_header"
                >
                  <h2 className="heading">Select Category</h2>
                </AccordionSummary>
                <AccordionDetails>
                  <Box className="panel_content">
                    <div className="categories mt-6">
                      {productStats?.categories?.map((data) => (
                        <FormControlLabel
                          key={data._id}
                          control={
                            <Checkbox
                              checked={checkedCategories.includes(data._id)}
                              onChange={() => handleCheckboxChange(data._id)}
                              name={data.title}
                              color="primary"
                            />
                          }
                          label={data.title}
                        />
                      ))}
                    </div>
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3-content"
                  id="panel3-header"
                  className="accr_header"
                >
                  <h2 className="heading">Select Color</h2>
                </AccordionSummary>
                <AccordionDetails>
                  <Box className="panel_content">
                    <div className="colors pt-6 pb-2 px-1">
                      {productStats?.colors?.map((color) => (
                        <div
                          key={color}
                          className={`hex ${
                            selectedColors.includes(color) ? "selected" : ""
                          }`}
                          style={{ background: color }}
                          onClick={() => handleColorClick(color)}
                        ></div>
                      ))}
                    </div>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid lg={9}>
              <Box>
                <Grid container spacing={3}>
                  {allProducts?.length > 0 &&
                    allProducts?.map((product) => {
                      let is_exist = !!cartData?.find(
                        (productData) =>
                          productData.sizeId ===
                          product?.variants[0]?.sizes[0]?._id
                      );
                      let quantity = Number(
                        product?.variants[0]?.sizes[0]?.quantity
                      );

                      return (
                        <Grid lg={4}>
                          <div className="lists">
                            <img
                              className="cart_img"
                              src={
                                is_exist
                                  ? "images/cart_full.png"
                                  : "images/cart_empty.png"
                              }
                              onClick={() => {
                                if (!is_exist) {
                                  addToCart(
                                    product._id,
                                    product?.variants[0]?._id,
                                    product?.variants[0]?.sizes[0]?._id
                                  );
                                } else {
                                  removeFromCart(
                                    product._id,
                                    product?.variants[0]?._id,
                                    product?.variants[0]?.sizes[0]?._id
                                  );
                                }
                              }}
                            />
                            <img
                              className="list_banner"
                              src={product?.variants[0]?.images[0]}
                            />
                            <p className="pro_title mb-4">
                              {capitalizeFirstLetter(product.title)}
                            </p>
                            <p className="pro_rate mb-4"></p>
                            {formatCurrency(
                              product?.variants[0]?.sizes[0]?.price
                            )}
                            <Grid xs={12} className="ctaBtn text-center">
                              <Button
                                onClick={() =>
                                  navigate(
                                    `${userRoutes.productDetail}?productId=${product._id}`
                                  )
                                }
                                variant="contained"
                                className="submitBtn mdBtn"
                              >
                                Buy Now
                              </Button>
                            </Grid>
                          </div>
                        </Grid>
                      );
                    })}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Footer />
      </Box>
    </>
  );
};

export default ProductsData;
